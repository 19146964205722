import { __awaiter } from "tslib";
import { showModal } from '../modals/modalTools';
import { get } from 'svelte/store';
import newQuery from '../query/newQuery';
import ImportExportModal from '../modals/ImportExportModal.svelte';
import getElectron from './getElectron';
import { currentDatabase, extensions, getCurrentDatabase } from '../stores';
import { getUploadListener } from './uploadFiles';
import getConnectionLabel, { getDatabaseFileLabel } from './getConnectionLabel';
import { apiCall } from './api';
import openNewTab from './openNewTab';
import { openJsonDocument } from '../tabs/JsonTab.svelte';
import { SAVED_FILE_HANDLERS } from '../appobj/SavedFileAppObject.svelte';
import _ from 'lodash';
import ErrorMessageModal from '../modals/ErrorMessageModal.svelte';
export function canOpenByElectron(file, extensions) {
    var _a;
    if (!file)
        return false;
    const nameLower = file.toLowerCase();
    if (nameLower.endsWith('.sql'))
        return true;
    if (nameLower.endsWith('.diagram'))
        return true;
    if (nameLower.endsWith('.qdesign'))
        return true;
    if (nameLower.endsWith('.perspective'))
        return true;
    if (nameLower.endsWith('.json'))
        return true;
    if (nameLower.endsWith('.db') || nameLower.endsWith('.sqlite') || nameLower.endsWith('.sqlite3'))
        return true;
    for (const format of extensions.fileFormats) {
        if (nameLower.endsWith(`.${format.extension}`))
            return true;
        if ((_a = format.extensions) === null || _a === void 0 ? void 0 : _a.find(ext => nameLower.endsWith(`.${ext}`)))
            return true;
    }
    return false;
}
export function openSqliteFile(filePath) {
    return __awaiter(this, void 0, void 0, function* () {
        const defaultDatabase = getDatabaseFileLabel(filePath);
        const resp = yield apiCall('connections/save', {
            _id: undefined,
            databaseFile: filePath,
            engine: 'sqlite@dbgate-plugin-sqlite',
            singleDatabase: true,
            defaultDatabase,
        });
        currentDatabase.set({
            connection: resp,
            name: getDatabaseFileLabel(filePath),
        });
    });
}
function getFileEncoding(filePath, fs) {
    var buf = Buffer.alloc(5);
    var fd = fs.openSync(filePath, 'r');
    fs.readSync(fd, buf, 0, 5, 0);
    fs.closeSync(fd);
    // https://en.wikipedia.org/wiki/Byte_order_mark
    let e = null;
    if (!e && buf[0] === 0xef && buf[1] === 0xbb && buf[2] === 0xbf)
        e = 'utf8';
    if (!e && buf[0] === 0xfe && buf[1] === 0xff)
        e = 'utf16be';
    if (!e && buf[0] === 0xff && buf[1] === 0xfe)
        e = 'utf16le';
    if (!e)
        e = 'utf8';
    return e;
}
function decodeFile(buf, enc) {
    const iconv = window.require('iconv-lite');
    return iconv.decode(buf, enc);
}
function openElectronJsonLinesFile(filePath, parsed) {
    openNewTab({
        title: parsed.name,
        tooltip: filePath,
        icon: 'img archive',
        tabComponent: 'ArchiveFileTab',
        props: {
            jslid: `file://${filePath}`,
        },
    });
}
function openSavedElectronFile(filePath, parsed, folder) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const handler = SAVED_FILE_HANDLERS[folder];
        const resp = yield apiCall('files/load-from', { filePath, format: handler.format });
        const connProps = {};
        let tooltip = undefined;
        const db = getCurrentDatabase();
        if (handler.currentConnection) {
            const connection = (db === null || db === void 0 ? void 0 : db.connection) || {};
            const database = db === null || db === void 0 ? void 0 : db.name;
            connProps.conid = (_a = db === null || db === void 0 ? void 0 : db.connection) === null || _a === void 0 ? void 0 : _a._id;
            connProps.database = database;
            tooltip = `${getConnectionLabel(connection)}\n${database}`;
        }
        openNewTab({
            title: parsed.name,
            icon: handler.icon,
            tabComponent: handler.tabComponent,
            tooltip,
            props: Object.assign({ savedFile: null, savedFolder: null, savedFilePath: filePath, savedFormat: handler.format }, connProps),
        }, { editor: resp });
    });
}
export function openElectronFileCore(filePath, extensions) {
    const nameLower = filePath.toLowerCase();
    const path = window.require('path');
    const fs = window.require('fs');
    const parsed = path.parse(filePath);
    const uploadListener = getUploadListener();
    if (nameLower.endsWith('.sql')) {
        const encoding = getFileEncoding(filePath, fs);
        const buf = fs.readFileSync(filePath);
        const data = decodeFile(buf, encoding);
        newQuery({
            title: parsed.name,
            initialData: data,
            // @ts-ignore
            savedFilePath: filePath,
            savedFormat: 'text',
        });
        return;
    }
    if (nameLower.endsWith('.db') || nameLower.endsWith('.sqlite') || nameLower.endsWith('.sqlite')) {
        openSqliteFile(filePath);
        return;
    }
    if (nameLower.endsWith('.jsonl') || nameLower.endsWith('.ndjson')) {
        openElectronJsonLinesFile(filePath, parsed);
        return;
    }
    if (nameLower.endsWith('.json')) {
        fs.readFile(filePath, { encoding: 'utf-8' }, (err, data) => {
            if (err) {
                showModal(ErrorMessageModal, { message: (err === null || err === void 0 ? void 0 : err.message) || 'File cannot be loaded' });
            }
            else {
                try {
                    const json = JSON.parse(data);
                    openJsonDocument(json);
                }
                catch (err) {
                    showModal(ErrorMessageModal, { message: err.message });
                }
            }
        });
        return;
    }
    if (nameLower.endsWith('.diagram')) {
        openSavedElectronFile(filePath, parsed, 'diagrams');
        return;
    }
    if (nameLower.endsWith('.qdesign')) {
        openSavedElectronFile(filePath, parsed, 'query');
        return;
    }
    if (nameLower.endsWith('.perspective')) {
        openSavedElectronFile(filePath, parsed, 'perspectives');
        return;
    }
    for (const format of extensions.fileFormats) {
        if (nameLower.endsWith(`.${format.extension}`)) {
            if (uploadListener) {
                uploadListener({
                    filePath,
                    storageType: format.storageType,
                    shortName: parsed.name,
                });
            }
            else {
                showModal(ImportExportModal, {
                    openedFile: {
                        filePath,
                        storageType: format.storageType,
                        shortName: parsed.name,
                    },
                    importToCurrentTarget: true,
                    initialValues: {
                        sourceStorageType: format.storageType,
                    },
                });
            }
        }
    }
}
function getFileFormatFilters(extensions) {
    return extensions.fileFormats
        .filter(x => x.readerFunc)
        .map(x => ({ name: x.name, extensions: x.extensions || [x.extension] }));
}
function getFileFormatExtensions(extensions) {
    return _.flatten(extensions.fileFormats.filter(x => x.readerFunc).map(x => x.extensions || [x.extension]));
}
export function openElectronFile() {
    return __awaiter(this, void 0, void 0, function* () {
        const electron = getElectron();
        const ext = get(extensions);
        const filePaths = yield electron.showOpenDialog({
            filters: [
                {
                    name: `All supported files`,
                    extensions: [
                        'sql',
                        'sqlite',
                        'db',
                        'sqlite3',
                        'diagram',
                        'qdesign',
                        'perspective',
                        'json',
                        ...getFileFormatExtensions(ext),
                    ],
                },
                { name: `SQL files`, extensions: ['sql'] },
                { name: `JSON files`, extensions: ['json'] },
                { name: `Diagram files`, extensions: ['diagram'] },
                { name: `Query designer files`, extensions: ['qdesign'] },
                { name: `Perspective files`, extensions: ['perspective'] },
                { name: `SQLite database`, extensions: ['sqlite', 'db', 'sqlite3'] },
                ...getFileFormatFilters(ext),
            ],
            properties: ['showHiddenFiles', 'openFile'],
        });
        const filePath = filePaths && filePaths[0];
        if (canOpenByElectron(filePath, ext)) {
            openElectronFileCore(filePath, ext);
        }
    });
}
